body {
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.display-row {
    display: flex;
    flex-direction: row;
}

.display-col {
    display: flex;
    flex-direction: column;
}

.widthmax {
    width: 100%;
}

.heightmax {
    height: 100vh;
}

.h25vh {
    height: 25vh;
}

.h5vh {
    height: 5vh;
}

.mar-left-2vw {
    margin-left: 2vw;
}

.mar-left-5vw {
    margin-left: 5vw;
}

.mar-rigth-2vw {
    margin-right: 2vw;
}

.mar-rigth-5vw {
    margin-right: 5vw;
}

.mar-bot-3p {
    margin-bottom: 3%;
}

.mar-bot-2vw {
    margin-bottom: 2vw;
}

.mar-top-3vw {
    margin-top: 3vw;
}

.mar-top-2vw {
    margin-top: 2vw;
}

.mar-top-1vw {
    margin-top: 1vw;
}

.align-items-left {
    align-items: flex-start;
}

.align-items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-flex-start {
    justify-content: flex-start;
}

.text-align-center {
    text-align: center;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 10px;
    gap: 20px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
    gap: 20px;
}

@media (min-width: 600px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 10px;
        gap: 20px;
    }
}

.border-radius1 {
    border-radius: 25px;
}

.padding-1 {
    padding: 0.6em 1.5em;
}

.padding-2 {
    padding: 0.75% 3.5%;
}

/* estilo botones */
.bn3 {
    display: inline-block;
    border: 0.07em solid rgb(255, 255, 255);
    border-radius: 2em;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
    transition: background-color 1s, color 1s;
}

.bn3.selected {
    color: rgb(255, 255, 255);
    background-color: black;
}

.slide-left {
    animation: slide-left .7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-right {
    animation: slide-right .7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-left {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.imgProd {
    width: 85%;
    height: 80%;
    max-width: 100%;
    max-height: 95px;
    border-radius: 10px;
    margin-top: 10px;
}

.flip-vertical-right {
    -webkit-animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
}

@keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
}